import React, { PropsWithChildren, PureComponent } from 'react';

import styled from 'styled-components';

import { Colors } from '@shared/interfaces/themes';
import { breakpoint } from 'theme';
import { getStyle } from '@shared/markup/functions';

type CircleProps = {
  className?: string;
  backgroundColor?: keyof Colors | string;
  size?: number;
  smSize?: number;
  mSize?: number;
  lgSize?: number;
  onClick?: () => void;
};

class Circle extends PureComponent<PropsWithChildren<CircleProps>> {
  render() {
    const { children, className, onClick } = this.props;

    return (
      <div data-cy="circle-test" className={className} onClick={onClick}>
        {children}
      </div>
    );
  }
}

const styledCircle = styled(Circle)`
  display: flex;
  border-radius: 50%;
  overflow: hidden;

  ${props => getStyle(props.size, 'width', 'em')}
  ${props => getStyle(props.size, 'min-width', 'em')}
  ${props => getStyle(props.size, 'height', 'em')}

  ${props => breakpoint.down('ltm')`
    ${getStyle(props.smSize, 'width', 'em')}
    ${getStyle(props.smSize, 'min-width', 'em')}
    ${getStyle(props.smSize, 'height', 'em')}
  `}

  ${props => breakpoint.only('m', 'ltLg')`
    ${getStyle(props.mSize, 'width', 'em')}
    ${getStyle(props.mSize, 'min-width', 'em')}
    ${getStyle(props.mSize, 'height', 'em')}
  `}

  ${props => breakpoint.up('lg')`
    ${getStyle(props.lgSize, 'width', 'em')}
    ${getStyle(props.lgSize, 'min-width', 'em')}
    ${getStyle(props.lgSize, 'height', 'em')}
  `}

  justify-content: center;
  align-items: center;
  transition: background 0.25s;
  background: ${props => props.theme.colors[props.backgroundColor]};
`;

styledCircle.defaultProps = {
  size: 2.5,
};

export default styledCircle;
