import React, { Fragment, PureComponent } from 'react';

import styled, { css } from 'styled-components';

import AppIcon from '@components/atoms/AppIcon/AppIcon';
import CSSMediaQuery, { ShowOn } from '../atoms/CSSMediaQuery/CSSMediaQuery';
import ExternalLink from '../atoms/ExternalLink/ExternalLink';
import Text from '../atoms/Text/Text';
import MobileNavbar from '../molecules/MobileNavbar';
import Navbar from '../molecules/Navbar';

import { CORPSITE_URL } from '@utils/origin';

import { ROUTE } from '@utils/index';

import { CA_ICON } from '@shared/markup/constants';
import { I18nTranslation } from '@shared/interfaces/i18n';
import { LANGUAGE } from '@shared/application/constants';
import { Menu } from '@shared/interfaces/menu-item';
import { breakpoint } from 'theme';
import { withTranslation } from 'i18n';

const tabletStyle = css`
  ${breakpoint.only('m', 'med')`
    display: block;
  `}

  ${breakpoint.med`
    display: none;
  `}
`;

const Hamburger = styled.button`
  width: 1.375em;
  height: 1em;
  cursor: pointer;
  position: relative;
  border: none;
  background: none;

  ${tabletStyle};
`;

const Stick = styled.div<{ isOpenMainMenu: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${props => props.theme.colors.white};
  border-radius: 0.125em;
  height: 0.125em;
  width: 100%;
  transition: 0.25s ease-in-out;

  &:nth-child(2),
  &:nth-child(3) {
    top: 7px;
  }

  &:nth-child(4) {
    top: 14px;
  }

  ${props =>
    props.isOpenMainMenu &&
    `
    &:nth-child(1) {
      top: 7px;
      width: 0%;
      left: 50%;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
    }

    &:nth-child(4) {
      top: 7px;
      width: 0%;
      left: 50%;
    }
  `}
`;

const HeaderBox = styled.div`
  background-color: ${props => props.theme.colors.kuroiBlack};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  border-bottom: 1px solid ${({ theme }) => theme.transparentColors.black15};
  display: flex;
  align-items: center;
  padding-left: 1.5em;
  padding-right: 1em;
  height: 80px;
  justify-content: space-between;
`;

const MobileNavbarExtend = styled(MobileNavbar)`
  ${tabletStyle};
`;

const ExternalLinkExtend = styled(ExternalLink)`
  display: flex;
  align-items: center;
  text-align: center;
  flex-grow: 1;
  max-width: max-content;

  ${breakpoint.med`
    text-align: left;
  `}
`;

const StyledHeader = styled(Text)`
  color: white;
  font-size: 1.25em;

  ${breakpoint.m`
    font-size: 1.75em;
  `}
`;

const StyledLink= styled(ExternalLink)`
  opacity: 0;
  font-size: 15px;
  color: ${props => props.theme.colors.white};
  font-weight: 700;
  pointer-events: none;
  text-align: center;
  &:focus {
    opacity: 1;
  }
`;

const AppIconExtend = styled(AppIcon)`
  margin: 0 0.5em;

  ${breakpoint.m`
    margin: 0 1.5em;
  `}
`;

type HeaderProps = {
  avatar?: string;
  userName?: string;
  isAuthorized?: boolean;
  onSignUp: () => void;
  pathname: string;
  i18n: { language: string };
  t: I18nTranslation;
};

type HeaderState = {
  isOpenMainMenu: boolean;
};

class Header extends PureComponent<HeaderProps> {
  state: HeaderState = {
    isOpenMainMenu: false,
  };

  origin = `${CORPSITE_URL}`;

  getCurrentLang(lang) {
    switch (lang) {
      case LANGUAGE.Chinese:
        return 'cn.';
      case LANGUAGE.Spanish:
        return 'es.';
      case LANGUAGE.French:
        return 'fr.';
      case LANGUAGE.German:
        return 'de.';
      case LANGUAGE.English:
        return '';
    }
  }

  get menu(): Menu[] {
    return [
      {
        id: 'student',
        url: `${this.origin}/student`,
        isActive: false,
        externalLink: false,
        color: 'white',
        order: 1,
      },
      {
        id: 'professional',
        url: `${this.origin}/professional`,
        isActive: false,
        externalLink: false,
        color: 'white',
        order: 2,
      },
      {
        id: 'institutional',
        url: `${this.origin}/institutional`,
        isActive: false,
        externalLink: false,
        color: 'white',
        order: 3,
      },
      {
        id: 'pricing',
        url: ROUTE.PRICING,
        isActive: this.props.pathname !== ROUTE.ACCOUNT.INDEX,
        externalLink: false,
        color: 'white',
        order: 4,
      },
      {
        id: 'helpCenter',
        url: `https://${this.getCurrentLang(
          this.props.i18n.language
        )}service.elsevier.com/app/home/supporthub/complete-anatomy/`,
        isActive: false,
        externalLink: false,
        color: 'white',
        order: 5,
      },
    ];
  }

  handleMenu = () => {
    this.setState((state: HeaderState) => ({
      isOpenMainMenu: !state.isOpenMainMenu,
    }));
  };

  render() {
    const { pathname, isAuthorized, avatar, userName, t } = this.props;
    const isActive = pathname === ROUTE.ACCOUNT.INDEX;

    return (
      <Fragment>
        <HeaderBox aria-label="Branding Box">
          <ExternalLinkExtend href={this.origin} ariaLabel="Complete Anatomy from Elsevier - Home">
            <AppIconExtend icons={CA_ICON} smSize={60} size={60} />
            <CSSMediaQuery showOn={[ShowOn.Tablet, ShowOn.Desktop]}>
              <StyledHeader>Complete Anatomy</StyledHeader>
            </CSSMediaQuery>
          </ExternalLinkExtend>
          <StyledLink href="#main-content" ariaLabel="Skip to main content">
            {t('header.skip')}
          </StyledLink>
          <nav role="navigation">
            <Navbar
              items={this.menu}
              isAuthorized={isAuthorized}
              avatar={avatar}
              userName={userName}
              isActive={isActive}
              onClickTiff={this.props.onSignUp}
            />
            <Hamburger onClick={this.handleMenu} aria-label='Navigation'>
              <Stick isOpenMainMenu={this.state.isOpenMainMenu} />
              <Stick isOpenMainMenu={this.state.isOpenMainMenu} />
              <Stick isOpenMainMenu={this.state.isOpenMainMenu} />
              <Stick isOpenMainMenu={this.state.isOpenMainMenu} />
            </Hamburger>
          </nav>
        </HeaderBox>
        <MobileNavbarExtend
          isOpen={this.state.isOpenMainMenu}
          items={this.menu}
          isAuthorized={isAuthorized}
          avatar={avatar}
          userName={userName}
          isActive={isActive}
        />
      </Fragment>
    );
  }
}

export default withTranslation('common')(Header);
