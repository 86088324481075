import React, { PureComponent } from 'react';

import styled from 'styled-components';

import Button from '../atoms/Button/Button';

import CrossIcon from '@public/icons/cross.svg';

const CrossIconExtend = styled(CrossIcon)`
  color: ${props => props.theme.colors.middleGrey};
`;

const ButtonExtend = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  right: calc(50% - 0.875em);
  top: -2.75em;
  background-color: ${props => props.theme.colors.white};
  border-radius: 50%;
  height: 2.25em;
  width: 2.25em;
`;

type CrossProps = {
  onClick: () => void;
};

export default class Cross extends PureComponent<CrossProps> {
  render() {
    return (
      <ButtonExtend onClick={this.props.onClick}>
        <CrossIconExtend />
      </ButtonExtend>
    );
  }
}
