import React, { PropsWithChildren } from 'react';

import styled from 'styled-components';

import _isArray from 'lodash/isArray';

import { breakpoint } from 'theme';

export enum ShowOn {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
}

type RenderComponentProps = {
  className?: string;
  showOn: ShowOn[];
};

type RenderComponent = React.DetailedReactHTMLElement<
  { key: string; className: string },
  HTMLElement
>;

function renderComponent(children, className, i = className): RenderComponent {
  return React.cloneElement(children, {
    key: i,
    className: className,
  });
}

const CSSMediaQuery = styled(
  ({ children, className }: PropsWithChildren<RenderComponentProps>): any => {
    if (_isArray(children)) {
      return children.map((child, i) => renderComponent(child, className, i));
    }

    return renderComponent(children, className);
  }
)`
  ${props =>
    props.showOn &&
    !props.showOn.includes(ShowOn.Mobile) &&
    breakpoint.down('ltm')`
    display: none!important;
  `}
  ${props =>
    props.showOn &&
    !props.showOn.includes(ShowOn.Tablet) &&
    breakpoint.only('m', 'ltLg')`
    display: none!important;
  `}
  ${props =>
    props.showOn &&
    !props.showOn.includes(ShowOn.Desktop) &&
    breakpoint.up('lg')`
    display: none!important;
  `}
`;

CSSMediaQuery.defaultProps = {
  showOn: [],
};

export default CSSMediaQuery;
