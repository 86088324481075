import React, { PropsWithChildren, PureComponent } from 'react';

import styled from 'styled-components';

import { Colors } from '@shared/interfaces/themes';
import { Target } from '@shared/enums/target';

type ExternalLinkProps = {
  className?: string;
  href: string;
  target?: Target;
  ariaLabel?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  color?: keyof Colors;
  textDecoration?: string;
};

class ExternalLink extends PureComponent<PropsWithChildren<ExternalLinkProps>> {
  render() {
    return (
      <a
        href={this.props.href}
        className={this.props.className}
        aria-label={this.props.ariaLabel}
        target={this.props.target}
        onClick={this.props.onClick}
        role="link"
      >
        {this.props.children}
      </a>
    );
  }
}

const StyledExternalLink = styled(ExternalLink)`
  text-decoration: ${props => props.textDecoration || 'none'};
  color: ${props =>
    props.color ? props.theme.colors[props.color] : 'inherit'};
`;

export default StyledExternalLink;
