import React, { PureComponent } from 'react';

import styled, { css } from 'styled-components';

import Box from '../atoms/Box/Box';
import Button from '../atoms/Button/Button';
import Circle from '../atoms/Circle/Circle';
import ExternalLink from '../atoms/ExternalLink/ExternalLink';
import Icon from '../atoms/Icon';
import Image from '../atoms/Image/Image';
import Link from '../atoms/Link/Link';
import Text from '../atoms/Text/Text';

import { ROUTE } from '@utils/index';
import analyticsManager from '@utils/analyticsManager';

import { Color } from '@shared/enums/button-color';
import { DEFAULT_AVATAR } from '@shared/markup/constants';
import { HaveAccountPopup } from '@hoc/HaveAccountPopup';
import { I18nTranslation } from '@shared/interfaces/i18n';
import { Menu } from '@shared/interfaces/menu-item';
import { Session } from 'next-auth';
import { Size } from '@shared/enums/button-size';
import { Target } from '@shared/enums/target';
import { breakpoint } from 'theme';
import { withTranslation } from 'i18n';
import { withUser } from '@hoc/withUser';

const StyledButton = styled(Button)`
  white-space: nowrap;
`;

const DefaultImage = styled(Image)`
  height: 1.5em;
  margin-bottom: -0.25em;
`;

const TextExtend = styled(Text)`
  font-size: 15px;
  white-space: nowrap;
  font-weight: 700;

  ${props =>
    props.isActive &&
    css`
      text-decoration: underline;
      text-underline-offset: 4px;
      text-decoration-color: ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.white};
    `
  };

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.colors.turquoise} !important;
    text-underline-offset: 4px;
  }
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  margin-right: 2em;
`;

const ItemAccount = styled(Item)`
  margin-right: 2em;

  &:last-child {
    margin-right: 0.75em;
  }
`;

const sharedLinkCss = css`
  padding: 0 0.075em 0;
  color: ${props => props.theme.colors.white};
`;

const ExternalLinkExtend = styled(ExternalLink)`
  ${sharedLinkCss};
`;

const LinkExtend = styled(Link)`
  ${sharedLinkCss};
`;

const HoverableIcon = styled(Icon)`
  position: relative;

  &:hover::after {
    content: '';
    position: absolute;
    bottom: -3px;
    width: 100%;
    height: 2px;
    background-color: ${props => props.theme.colors.turquoise};
    transition: color 0.2s;
  }

  ${props=>
    props.isActive &&
    css`
      &::after {
        content: '';
        position: absolute;
        bottom: -3px;
        width: 100%;
        height: 2px;
        background-color: ${props => props.theme.colors.white};
      }
    `
  }
`

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

type NavbarProps = {
  className?: string;
  items: Menu[];
  isAuthorized?: boolean;
  isActive: boolean;
  onClickTiff: () => void;
  t: I18nTranslation;
  showHaveAccountPopup?: (
    p: null,
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  user?: Session['user'];
};

class Navbar extends PureComponent<NavbarProps> {
  t = key => this.props.t(`header.${key}`);

  handleAccountClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    this.props.showHaveAccountPopup(null, event);
  };

  renderAvatar = () => {
    const { isActive, user } = this.props;

    const avatarEl = user?.image ? (
      <Image src={user.image} height={2.125} />
    ) : (
      <DefaultImage src={DEFAULT_AVATAR} />
    );

    return (
      <ItemAccount>
        <LinkExtend href={ROUTE.ACCOUNT.INDEX}>
          <InnerWrapper>
            <Circle backgroundColor="white" size={1.75}>
              {avatarEl}
            </Circle>
            <Box ml={0.75}>
              <TextExtend isActive={isActive} weight={600}>
                {user?.name.split(' ')[0] || this.t('myAccount')}
              </TextExtend>
            </Box>
          </InnerWrapper>
        </LinkExtend>
      </ItemAccount>
    );
  };

  renderAccountLink = () => {
    const color = this.props.isActive ? 'turquoise' : 'white';

    return (
      <ItemAccount>
        <LinkExtend
          href={ROUTE.ACCOUNT.INDEX}
          onClick={this.handleAccountClick}
        >
          <HoverableIcon name="account" color='white' size={1.25} isActive={this.props.isActive} />
        </LinkExtend>
      </ItemAccount>
    );
  };

  render() {
    const { className, items, isAuthorized } = this.props;

    items.sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      }

      if (a.order < b.order) {
        return -1;
      }

      return 0;
    });

    return (
      <ul className={className}>
        {items.map(item => {
          const Component =
            item.url[0] === '/' ? LinkExtend : ExternalLinkExtend;
          const target = item.externalLink ? Target.Blank : Target.Self;

          return (
            <Item key={item.id}>
              <Component
                href={item.url}
                target={target}
                onClick={() => {
                  analyticsManager.google.addItemView(item.id);
                }}
              >
                <TextExtend
                  isActive={item.isActive}
                  weight={600}
                  color={item.color}
                >
                  {this.t(item.id)}
                </TextExtend>
              </Component>
            </Item>
          );
        })}
        {isAuthorized ? this.renderAvatar() : this.renderAccountLink()}
        {!isAuthorized && (
          <ItemAccount>
            <StyledButton
              onClick={this.props.onClickTiff}
              colorType={Color.FullTurquoiseBlack}
              sizeType={Size.Six}
            >
              {this.t('try')}
            </StyledButton>
          </ItemAccount>
        )}
      </ul>
    );
  }
}

const NavbarWithUser = withUser(Navbar);

const styledNavbar = styled(withTranslation('common')(NavbarWithUser))`
  display: none;

  ${breakpoint.med`
    display: flex;
  `}
`;

export default HaveAccountPopup(styledNavbar);
