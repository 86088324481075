import React, { PureComponent } from 'react';

import styled from 'styled-components';

import AppIcon from '../atoms/AppIcon/AppIcon';
import { Col, Row } from '../atoms/Grid';
import Text from '../atoms/Text/Text';

import { CA_ICON } from '@shared/markup/constants';

import { breakpoint } from 'theme';

type HeaderCAProps = {
  className?: string;
  discount?: string;
};

class HeaderCA extends PureComponent<HeaderCAProps> {
  render() {
    const { className, discount } = this.props;

    return (
      <div className={className}>
        <Row middle="xs" width="100%">
          <ColApp aria-label="Branding Box" xs={12} sm={6} lg={4}>
            <AppIconExtend icons={CA_ICON} size={60} />
            <Text color="white" size={1.5}>
              <span
                dangerouslySetInnerHTML={{
                  __html: 'Complete Anatomy',
                }}
              />
            </Text>
          </ColApp>

          {discount && (
            <ColDiscount xs={false} sm={6} lg={4}>
              <Text color="white" size={1.125} weight="bold">
                {discount}
              </Text>
            </ColDiscount>
          )}
          <Col xs={false} sm={false} lg={4} />
        </Row>
      </div>
    );
  }
}

const StyledHeaderCA = styled(HeaderCA)`
  padding: 0.625rem 1.25rem;
  background-color: ${props => props.theme.colors.cornflowerBlue};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
`;

const AppIconExtend = styled(AppIcon)`
  margin-right: 0.5em;
`;

const ColApp = styled(Col)`
  align-items: center;
  display: flex;
`;

const ColDiscount = styled(Col)`
  text-align: right;

  ${breakpoint.lg`
    text-align: center;
  `}
`;

export default StyledHeaderCA;
