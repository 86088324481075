import React from 'react';

import styled, { css } from 'styled-components';

import { breakpoint } from 'theme';

type ImageProps = {
  className?: string;
  src: string;
  srcSet?: string[];
  display?: string;
  height?: number | string;
  width?: number | string;
  mWidth?: number | string;
  lgWidth?: number | string;
  isDull?: number | string;
  zindex?: number;
  alt?: string;
};

const Image = styled(
  ({ srcSet, src, className, height, width, alt }: ImageProps) => {
    return (
      <img
        role="img"
        className={className}
        src={src}
        srcSet={
          srcSet ? `${srcSet[0]} 1x, ${srcSet[1]} 2x, ${srcSet[2]} 3x` : ''
        }
        height={height}
        width={width}
        alt={alt ?? ''}
      />
    );
  }
)`
  ${props =>
    props.isDull &&
    css`
      filter: grayscale(100%);
    `};
  ${props =>
    props.height &&
    css`
      height: ${typeof props.height === 'string'
        ? props.height
        : `${props.height}em`};
    `};
  ${props =>
    props.width &&
    css`
      width: ${typeof props.width === 'string'
        ? props.width
        : `${props.width}em`};
    `};
  ${props =>
    props.display &&
    css`
      display: ${props.display};
    `};
  ${props =>
    props.zindex &&
    css`
      z-index: ${props.zindex};
    `};

  ${breakpoint.m`
    ${props =>
      props.mWidth &&
      css`
        width: ${typeof props.mWidth === 'string'
          ? props.mWidth
          : `${props.mWidth}em`};
      `};
  `}

  ${breakpoint.lg`
    ${props =>
      props.lgWidth &&
      css`
        width: ${typeof props.lgWidth === 'string'
          ? props.lgWidth
          : `${props.lgWidth}em`};
      `};
  `}
`;

export default Image;
