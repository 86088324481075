import { useSession } from 'next-auth/react';

export const withUser = Component => props => {
  const session = useSession();

  // if the component has a render property, we are good
  if (Component.prototype.render) {
    return (
      <Component
        user={session?.data?.user}
        session={session?.data}
        updateSession={session?.update ?? (() => {})}
        {...props}
      />
    );
  }

  // if the passed component is a function component, there is no need for this wrapper
  throw new Error(
    [
      'You passed a function component, `withUser` is not needed.',
      'You can `useSession` directly in your component.',
    ].join('\n')
  );
};
