import React from 'react';

export const HaveAccountPopup = Component => {
  return function (props) {
    const showHaveAccountPopup = (callback, event) => {
      if (callback) {
        callback();
      }

      return false;
    };

    return <Component showHaveAccountPopup={showHaveAccountPopup} {...props} />;
  };
};
