import React, { PureComponent } from 'react';

import Box from '../atoms/Box/Box';
import Text from '../atoms/Text/Text';
import BottomMessage from '@components/organisms/BottomMessage';

import { Align } from '@shared/enums/align';
import { AnimationType } from '@shared/enums/animation-type';
import { I18nTranslation } from '@shared/interfaces/i18n';
import { Transform } from '@shared/enums/transform';
import { withTranslation } from 'i18n';

type AccessTokenWarningPopupProps = {
  isOpen: boolean;
  onClose: () => void;
  t: I18nTranslation;
};

class AccessTokenWarningPopup extends PureComponent<AccessTokenWarningPopupProps> {
  t = key => this.props.t(`popup.accessToken.${key}`);

  render() {
    const { onClose, isOpen } = this.props;

    return (
      <BottomMessage
        isOpen={isOpen}
        onClose={onClose}
        animationType={AnimationType.SlideExtend}
      >
        <Box pl={1.5} pr={1.5} pt={1.5} pb={0.875}>
          <Box pb={1} align={Align.Center}>
            <Text color="nevada" weight={600} transform={Transform.Uppercase}>
              {this.t('title')}
            </Text>
          </Box>
          <Box pb={1.5} align={Align.Center}>
            <Text color="middleGrey" lineHeight={1.4}>
              {this.t('text')}
            </Text>
          </Box>
        </Box>
      </BottomMessage>
    );
  }
}

export default withTranslation('common')(AccessTokenWarningPopup);
