import React, { PropsWithChildren, PureComponent } from 'react';

import styled, { css } from 'styled-components';

import Animation from '../atoms/Animation/Animation';
import Portal from '../atoms/Portal/Portal';

import { AnimationType } from '@shared/enums/animation-type';

const Wrapper = styled.div<PopupProps>`
  overflow: auto;
  display: flex;
  ${props =>
    props.variant === 'bottomMessage' &&
    css`
      height: 100%;
    `}
`;

const AnimationChild = styled(Animation)<PopupProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  z-index: 11;
  ${props =>
    props.variant === 'popup' &&
    css`
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
  ${props =>
    props.variant === 'bottomMessage' &&
    css`
      bottom: 0;
      width: 100%;
    `}
`;

const AnimationOverlay = styled(Animation)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
  overflow: hidden;
`;

const Overlay = styled.div`
  position: fixed;
  background-color: ${({ theme }) => theme.transparentColors.black50};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

type PopupProps = {
  className?: string;
  variant?: 'popup' | 'bottomMessage';
  in?: boolean;
  onClosePopup?: () => void;
  animationType?: AnimationType;
};

export default class Popup extends PureComponent<
  PropsWithChildren<PopupProps>
> {
  static defaultProps = {
    variant: 'popup',
    onClosePopup: () => {},
  };

  handleClose = (event: KeyboardEvent) => {
    const ESC = 27;
    if (this.props.in && event.keyCode === ESC) {
      this.props.onClosePopup();
    }
  };

  componentDidMount() {
    window.addEventListener('keydown', this.handleClose, false);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleClose, false);
  }

  render() {
    const { onClosePopup, className, variant, animationType, children } =
      this.props;

    return (
      <Portal node="popup">
        <AnimationOverlay in={this.props.in}>
          <Overlay onClick={onClosePopup} />
          <AnimationChild
            className={className}
            in={this.props.in}
            variant={variant}
            type={animationType}
          >
            <Wrapper variant={variant}>{children}</Wrapper>
          </AnimationChild>
        </AnimationOverlay>
      </Portal>
    );
  }
}
