import React, { Component, PropsWithChildren, ReactPortal } from 'react';

import ReactDOM from 'react-dom';

type PortalProps = {
  node: string;
};

class Portal extends Component<PropsWithChildren<PortalProps>> {
  private element: HTMLElement;

  componentDidMount() {
    this.element = document.getElementById(this.props.node);
    this.forceUpdate();
  }

  render() {
    if (this.element === undefined) {
      return null;
    }

    return ReactDOM.createPortal(
      this.props.children,
      this.element
    ) as ReactPortal;
  }
}

export default Portal;
