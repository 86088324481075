import React, { PureComponent } from 'react';

import styled from 'styled-components';

import { DEFAULT_ICON } from '@shared/markup/constants';
import { breakpoint } from 'theme';
import { getStyle } from '@shared/markup/functions';

type AppIconProps = {
  className?: string;
  icons: string | Record<string, string>;
  size?: number;
  smSize?: number;
  mSize?: number;
  lgSize?: number;
};

class AppIcon extends PureComponent<AppIconProps> {
  render() {
    const { className, icons } = this.props;

    let source = {
      src: DEFAULT_ICON,
      srcSet: undefined,
    };

    if (Object.keys(icons).length !== 0) {
      source = {
        src: icons['1x'],
        srcSet: `${icons['1x']} 1x, ${icons['2x']} 2x, ${icons['3x']} 3x`,
      };
    }

    if (typeof icons === 'string') {
      source = {
        src: icons,
        srcSet: undefined,
      };
    }

    return (
      <img
        className={className}
        src={source.src}
        srcSet={source.srcSet}
        alt=""
        role="img"
      />
    );
  }
}

const styledAppIcon = styled(AppIcon)`
  height: ${props => props.size}px;
  width: ${props => props.size}px;

  ${breakpoint.down('ltm')`
    ${props => getStyle(props.smSize, 'width', 'px')}
    ${props => getStyle(props.smSize, 'height', 'px')}
  `}

  ${breakpoint.only('m', 'ltLg')`
    ${props => getStyle(props.mSize, 'width', 'px')}
    ${props => getStyle(props.mSize, 'height', 'px')}
  `}

  ${breakpoint.up('lg')`
    ${props => getStyle(props.lgSize, 'width', 'px')}
    ${props => getStyle(props.lgSize, 'height', 'px')}
  `}
`;

styledAppIcon.defaultProps = {
  size: 21,
};

export default styledAppIcon;
