export enum Size {
  Big = 'big',
  LessMedium = 'lessMedium',
  Medium = 'medium',
  Small = 'small',
  Huge = 'huge',
  FullWidth = 'fullWidth',
  FullWidthDefault = 'fullWidthDefault',
  MediumSquare = 'mediumSquare',
  One = 'one',
  OneMaxWidth = 'oneMaxWidth',
  Two = 'two',
  Three = 'three',
  Fourth = 'fourth',
  FourthMd = 'fourthMd',
  Fifth = 'fifth',
  FifthFullWidth = 'fifthFullWidth',
  Six = 'six',
  Seven = 'seven',
  Eight = 'eight',
  Ninth = 'ninth',
  Ten = 'ten',
}
