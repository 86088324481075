import React, { PropsWithChildren, PureComponent } from 'react';

import { Row as RowGrid } from 'react-styled-flexboxgrid';

import styled from 'styled-components';

import Col from './Col';

import { setRowGutter } from '@shared/markup/functions';

export type PaddingOptions = 'xs' | 'sm' | 'md' | 'lg';

type RowProps = {
  className?: string;
  gutter?: number;
  gutterXs?: number;
  gutterM?: number;
  gutterMd?: number;
  gutterLg?: number;
  width?: string;
  between?: PaddingOptions;
  middle?: PaddingOptions;
  bottom?: PaddingOptions;
  around?: PaddingOptions;
  center?: PaddingOptions;
  mb?: boolean | number;
};

class Row extends PureComponent<PropsWithChildren<RowProps>> {
  render() {
    return (
      <RowGrid role="row" {...this.props}>
        {this.props.children}
      </RowGrid>
    );
  }
}

const styledRow = styled(Row)`
  width: ${props => props.width};
  ${({ gutter }) => typeof gutter !== 'undefined' && setRowGutter(Col, gutter)}
  ${({ gutterXs }) =>
    typeof gutterXs !== 'undefined' && setRowGutter(Col, gutterXs)}
  ${({ gutterM }) =>
    typeof gutterM !== 'undefined' && setRowGutter(Col, gutterM)}
  ${({ gutterMd }) =>
    typeof gutterMd !== 'undefined' && setRowGutter(Col, gutterMd)}
  ${({ gutterLg }) =>
    typeof gutterLg !== 'undefined' && setRowGutter(Col, gutterLg)}
`;

export default styledRow;
