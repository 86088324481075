import React, { Component, PropsWithChildren } from 'react';

import styled from 'styled-components';

import Box from '../atoms/Box/Box';
import Cross from '../molecules/Cross';
import Popup from '../molecules/Popup';

import { AnimationType } from '@shared/enums/animation-type';
import { MEDIA_POINT, breakpoint } from 'theme';

const Wrapper = styled(Box)`
  max-height: 30em;
  overflow-y: scroll;

  @media screen and (max-width: ${MEDIA_POINT.LTM}px) and (orientation: landscape) {
    max-height: 15em;
  }

  ${breakpoint.m`
    max-height: none;
  `}
`;

const WrapperChildren = styled.div<{ size?: number }>`
  ${breakpoint.m`
    ${props =>
      props.size === 1 &&
      `
      width: 36.75em;
      margin-left: auto;
      margin-right: auto;
    `}
    
    ${props =>
      props.size === 3 &&
      `
      width: 25.75em;
      margin-left: auto;
      margin-right: auto;
    `}
  `}

  ${props =>
    props.size === 2 &&
    `
    width: 100%;
  `}
`;

const WrapperOuter = styled(Box)`
  width: 100%;
  z-index: 12;
`;

type BottomMessageProps = {
  className?: string;
  onClose?: () => void;
  isOpen?: boolean;
  animationType?: AnimationType;
  size?: 1 | 2 | 3;
};

export default class BottomMessage extends Component<
  PropsWithChildren<BottomMessageProps>
> {
  static defaultProps = {
    isOpen: false,
    size: 1,
  };

  render() {
    const { isOpen, onClose, animationType, className, children, size } =
      this.props;

    return (
      <Popup
        in={isOpen}
        variant="bottomMessage"
        onClosePopup={onClose}
        animationType={animationType}
      >
        <Cross onClick={onClose} />
        <WrapperOuter>
          <Wrapper className={className} backgroundColor="white">
            <WrapperChildren size={size}>{children}</WrapperChildren>
          </Wrapper>
        </WrapperOuter>
      </Popup>
    );
  }
}
