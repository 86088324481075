import React, { PropsWithChildren, PureComponent } from 'react';

import styled from 'styled-components';

import { Colors } from '@shared/interfaces/themes';
// import { Link as NextLink } from '../../../i18n';
import NextLink from 'next/link';
import { Target } from '@shared/enums/target';

type LinkProps = {
  className?: string;
  href: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  color?: keyof Colors;
  target?: Target;
  textDecoration?: string;
};

class Link extends PureComponent<PropsWithChildren<LinkProps>> {
  render() {
    /**
     * @todo remove <a> tag and use NextLink directly
     * Added legacyBehavior to NextLink to avoid the error about <a> tag inside
     */
    return (
      <NextLink legacyBehavior role="link" href={this.props.href}>
        <a className={this.props.className} onClick={this.props.onClick}>
          {this.props.children}
        </a>
      </NextLink>
    );
  }
}

const StyledLink = styled(Link)`
  text-decoration: ${props => props.textDecoration || 'none'};
  color: ${props =>
    props.color ? props.theme.colors[props.color] : 'inherit'};
`;

export default StyledLink;
